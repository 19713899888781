import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LayoutComponent } from './layout/components/layout/layout.component';
import { QueryParamsResolver } from './resolver/query-params.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'id/:id',
    redirectTo: 'home/:id',
  },

  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'contact',
        loadChildren: () => import('./contact/contact.module').then((m) => m.ContactModule),
        data: {
          sectionTitle: 'SERVICEPOINT.CONTACT.TITLE',
        },
      },
      {
        path: 'documents/:deviceId',
        loadChildren: () => import('./documents/documents.module').then((m) => m.DocumentsModule),
        data: {
          sectionTitle: 'SERVICEPOINT.DOCUMENTS.TITLE',
        },
      },
      {
        path: 'service/:deviceId',
        loadChildren: () => import('./service/service.module').then((m) => m.ServiceModule),
        data: {
          sectionTitle: 'SERVICEPOINT.SERVICE.TITLE',
        },
      },
      {
        path: 'notes/:deviceId',
        loadChildren: () => import('@ird/asset-management-notes-feature-notes-ng').then((m) => m.NotesNgModule),
        data: {
          sectionTitle: 'SERVICEPOINT.NOTES.TITLE',
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'scan',
        loadChildren: () => import('./scan/scan.module').then((m) => m.ScanModule),
        data: {
          sectionTitle: 'SERVICEPOINT.SCAN.TITLE',
        },
      },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
        data: {
          sectionTitle: 'SERVICEPOINT.HOME.TITLE',
        },
      },
      {
        path: 'qrassignment',
        loadChildren: () => import('./qr-assignment/qrassignment.module').then((m) => m.QrAssignmentModule),
        data: {
          sectionTitle: 'SERVICEPOINT.QRASSIGNMENT.TITLE',
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
        data: {
          sectionTitle: 'SERVICEPOINT.AUTH.TITLE',
        },
      },
      {
        path: 'registration',
        loadChildren: () => import('./registration/registration.module').then((m) => m.RegistrationModule),
        data: {
          sectionTitle: 'SERVICEPOINT.REGISTRATION.TITLE',
        },
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('./auth/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
        data: {
          pageTitle: 'SERVICEPOINT.AUTH.FORGOT_PASSWORD.RESET_PASSWORD_TITLE',
        },
      },
      {
        path: 'warranty/:deviceId',
        loadChildren: () => import('./warranty-extension/warranty-extension.module').then((m) => m.WarrantyExtensionModule),
        data: {
          pageTitle: 'SERVICEPOINT.WARRANTY.ELIGIBLE_WARRANTY_EXTENSION',
        },
      },
    ],
    resolve: {
      params: QueryParamsResolver,
    },
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
