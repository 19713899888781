import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PlacementCodeService {
  placementCode: string;

  setPlacementcode(placementCode: string): void {
    this.placementCode = placementCode;
  }

  getPlacementCode(): string {
    return this.placementCode;
  }
}
