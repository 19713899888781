import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SepoDevice } from '@deprecated/api-interfaces';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeviceRepository {
  constructor(private http: HttpClient) {}

  getDevice(deviceId: string): Observable<SepoDevice> {
    const url = environment.device.getDevice.replace('{assetId}', deviceId);
    return this.http.get<SepoDevice>(url);
  }

  addToPrivateTeam(deviceId: string): Observable<void> {
    const path = environment.device.addToPrivateTeam.replace('{deviceId}', deviceId);
    return this.http.post<void>(path, {});
  }
}
