import { ThresholdLevel } from '../threshold-level.enum';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ThresholdDTO {
  color?: number;
  enabled?: boolean;
  max?: number;
  min?: number;
  type!: ThresholdLevel;
}

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ConfiguredThresholdDTO extends ThresholdDTO {
  numValuesToTrigger?: number;
  name?: string;
}
