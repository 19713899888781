export default function endpoints() {
  const csApiUrl = '/api';
  const nestUrl = '/ui/api';
  const spUrl = '/sepo/api';

  const createUrl = (urlPath: string, urlPrefix: string) => {
    if (!urlPrefix.endsWith('/')) {
      urlPrefix = urlPrefix + '/';
    }

    const url =
      urlPrefix +
      urlPath
        .split('/')
        .filter((el) => el.length > 0)
        .join('/');

    if (urlPath.endsWith('/')) {
      return url + '/';
    }
    return url;
  };

  const createNestUrl = (urlPath: string) => createUrl(urlPath, nestUrl);
  const createCsUrl = (urlPath: string) => createUrl(urlPath, csApiUrl);
  const createSpUrl = (urlPath: string) => createUrl(urlPath, spUrl);

  return {
    api: {
      scs: csApiUrl,
      nest: nestUrl,
      sp: spUrl,
    },
    auth: {
      login: createNestUrl('/v1/servicepoint/login'),
      logout: createNestUrl('/v1/servicepoint/logout'),
      profile: createNestUrl('v1/profile'),
      passwordReset: createCsUrl('/servicepoint/v1/user/{username}/resetpassword'),
      newPassword: createSpUrl('/v2/user/{username}/resetpassword/{resetToken}'),
    },
    user: {
      apiEndpoint: createNestUrl('v1/profile'),
      userProfile: createCsUrl('/v4/user/profile'),
    },
    contact: {
      sendContact: createSpUrl('/v1/contact'),
      sendFile: createSpUrl('/v1/contact/{contactSessionId}/attachments/'),
    },
    device: {
      getDevice: createNestUrl('/v1/servicepoint/asset/{assetId}'),
      getSparepartKits: createCsUrl('/servicepoint/v1/device/{deviceId}/sparepartkits'),
      createDevice: createCsUrl('/servicepoint/v1/device/'),
      findDeviceByNameAndTenantID: createNestUrl('/v1/servicepoint/qr-assignment/model'),
      findDeviceBySerialNrAndTenantID: createNestUrl('/v1/servicepoint/qr-assignment/device/serial-number'),
      addToPrivateTeam: createNestUrl('/v1/servicepoint/private-asset/device/{deviceId}'),
    },
    warranty: {
      /**
       * Once, backend RPC methods are fixed, use the below endpoint instead of the REST endpoint.
       * Middleware is already in place.
       *
       * getWarrantyStatus: createNestUrl('/v1/servicepoint/warranty/status/{deviceId}'),
       */
      getWarrantyStatus: createSpUrl('v1/warranty/status/{deviceId}'),
      checkAndActivateExtendedWarranty: createSpUrl('v1/warranty/extensionrequest'),
    },
    file: {
      getManufacturerFileData: createCsUrl('/servicepoint/v2/file/device/{deviceId}/manufacturer'),
      getManufacturerFile: createCsUrl('/servicepoint/v1/file/device/{deviceId}/manufacturer/{fileId}'),
      getPreviewImage: createCsUrl('/servicepoint/v1/file/spareparts/{sparePartKitUUID}/previewimages'),
      getDocumentsByCategory: createCsUrl('/servicepoint/v1/file/device/{deviceId}/manufacturer/category'),
      userNotes: {
        getFile: createCsUrl('/v1/file/usernote/{noteId}/file/{fileId}'),
        uploadFile: createCsUrl('/v1/file/usernote/{noteId}/files'),
        getThumbnailImage: createCsUrl('/v1/file/usernote/{noteId}/thumbnail/{fileId}'),
        deleteAllFiles: createCsUrl('/v1/file/usernote/{noteId}/files'),
        deleteOneFile: createCsUrl('/v1/file/usernote/{noteId}/image/{fileId}'),
      },
    },
    kpi: {
      init: createSpUrl('/v1/initlogs'),
      website: createCsUrl('/v1/sepo/websitelogs'),
    },
    qrAssignment: {
      getModelsByTenant: createNestUrl('/v1/servicepoint/qr-assignment/tenant/{tenantId}/models'),
    },
    registration: {
      register: createNestUrl('/v1/servicepoint/onboarding/register'),
      resendEmail: createNestUrl('/v1/servicepoint/onboarding/resend-activation'),
      processUserActivation: createNestUrl('/v1/servicepoint/onboarding/process-activation'),
    },
    scan: {
      scanEvent: createSpUrl('/v1/pumplocations/{deviceId}'),
    },
    tenant: {
      configuration: createCsUrl('/servicepoint/v1/tenant/configuration'),
      tenantLogo: createCsUrl('/ui/v1/file/tenant/theme/logo/{fileId}'),
      tenantImageData: createCsUrl('/ui/v1/file/tenant/theme/logo/{fileId}'),
    },
    unleash: {
      get: createNestUrl('/v1/servicepoint/unleash/{id}'),
    },
    interaction: {
      sepoInteractions: createNestUrl('/v1/servicepoint/interaction'),
    },
    quote: {
      request: createSpUrl('/v1/quoterequest'),
    },
  };
}
