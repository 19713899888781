import { AddOnlyArray } from '@ird/shared-utils';

export class SecureAction {
  public static readonly SECURE_ACTIONS: AddOnlyArray<SecureAction> = [];

  public static readonly VERBS = {
    VIEW: 'view',
    CREATE: 'create',
    DELETE: 'delete',
    EDIT: 'edit',
    DUPLICATE: 'duplicate',
    UPLOAD: 'upload',
    SAVE: 'save',
  };

  get name(): string {
    return this._name;
  }

  protected constructor(private _name: string) {
    SecureAction.SECURE_ACTIONS.push(this);
  }

  public static convertToString(secureAction: string | string[] | SecureAction | SecureAction[]): string | string[] {
    let secureActionName: string | string[];

    if (secureAction instanceof Array) {
      secureActionName = secureAction.map((sak: string | SecureAction) => {
        if (sak instanceof SecureAction) {
          return sak.name;
        } else {
          return sak;
        }
      });
    } else if (secureAction instanceof SecureAction) {
      secureActionName = secureAction.name;
    } else {
      secureActionName = secureAction;
    }

    return secureActionName;
  }
}

// export class ExampleSecureAction extends SecureAction {
//   public static readonly ACTION_KEY: SecureAction = new ExampleSecureAction('myActionKey');
//   public static readonly EDIT_SENSOR: SecureAction = new ExampleSecureAction('sensor:edit');
//   private constructor(name: string) {
//     super(name);
//   }
// }
