/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class RegisterNewUser {
  user: UserToRegister;
  domain: string;
  recaptchaToken: string;
  recaptchaAction: string;
  onboardingMailTemplateLinks: OnboardingMailTemplateLinks;
  assetId?: string;
  userSettings: UserSettings;
}

export class UserToRegister {
  businessPhoneNumber: string;
  city: string;
  company: string;
  country: string;
  email: string;
  firstname: string;
  industry: string;
  jobTitle: string;
  lastname: string;
  password: string;
  postcode: string;
  streetAddress: string;
  region?: string;
  regionCode?: string;
}

class OnboardingMailTemplateLinks {
  verificationLink: string;
  systemLoginLink: string;
  systemLink: string;
}

class UserSettings {
  settings: string;
  locale: string;
  timeZone: string;
}
