import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Device, TenantSettings } from '@deprecated/api-interfaces';
import { IMAGE_ORIGIN } from '@ird/ng-core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { PagedData } from '../models';
import { ActiveAlarmWithConfigurations } from '../models/active-alarm';
import { DeviceMetadataModel } from '../models/device-metadata.model';
import { FeatureTree } from '../models/feature-tree.model';
import { NewDevice } from '../models/new-device.model';
import { DeviceRepositoryHttp, FileRepositoryUi } from '../repositories';
import { NestWebSocketService } from './nest-websocket.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private regex6L = new RegExp('6LS$');
  private regex12S = new RegExp('12S$');
  private regexSDP = new RegExp('SDP$');
  private regexBT = new RegExp('^BT?CS[OB]?');
  private regexBN = new RegExp('^[B]*N[SA]?');
  private regexBNA = new RegExp('^BNA');
  private regexBT2 = new RegExp('^[B]*T[A-Z]*');
  private regexMD = new RegExp('^MD[A-Z]*');
  private regexBE = new RegExp('^BE');
  private regexBW = new RegExp('^BW');
  private regexIU = new RegExp('^[IU]');

  private device: Device;
  private alarms: PagedData<ActiveAlarmWithConfigurations>;

  private previewDefaultDeviceImage: File;
  private previewDefaultEmailImage: File;

  constructor(
    private deviceRepositoryHttp: DeviceRepositoryHttp,
    @Optional() private nestWebSocket: NestWebSocketService,
    private fileRepositoryUi: FileRepositoryUi,
    private userService: UserService,
    @Inject(IMAGE_ORIGIN) private imageOrigin: string,
  ) {
    this.init();
  }

  private init(): void {
    this.nestWebSocket?.watch<string>('device/updated').subscribe((updatedDeviceId) => {
      if (this.device?.id === updatedDeviceId) {
        this.deviceRepositoryHttp.getDevice(updatedDeviceId).subscribe((response) => {
          Object.assign(this.device, response.body);
        });
      }
    });
  }

  async getDevicesList(page = 0, pageSize = 10, query?: string, filter?: Array<{ key: string; value: string | boolean }>) {
    const now =
      // eslint-disable-next-line radix
      parseInt(moment(Date.now()).subtract(20, 'minutes').format('X'), 10) * Math.pow(10, 9);
    const res = await this.deviceRepositoryHttp.getDevices(page, pageSize, query, filter).toPromise();

    res.body.content.forEach((element) => {
      if (element.lastActiveNanoSeconds && element.lastActiveNanoSeconds > now) {
        element.isOnline = 'Online';
      } else {
        element.isOnline = 'Offline';
      }
    });
    return res.body;
  }

  getDevices(
    page = 0,
    pageSize = 10,
    query?: string,
    filter?: Array<{ key: string; value: string | boolean }>,
  ): Observable<HttpResponse<PagedData<Device>>> {
    return this.deviceRepositoryHttp.getDevices(page, pageSize, query, filter);
  }

  getDevicesBody(page = 0, pageSize = 10, query?: string) {
    return this.getDevices(page, pageSize, query).pipe(map((x) => x.body));
  }

  getAllDevices(): Observable<HttpResponse<PagedData<Device>>> {
    return this.deviceRepositoryHttp.getAllDevices();
  }

  getDeviceCountForGroupsIds(groupsIds: string[]): Observable<any> {
    return this.deviceRepositoryHttp.getDeviceCountForGroupsIds(groupsIds);
  }

  setDetailDevice(device: Device): void {
    this.device = device;
  }

  getDevicesByIdList(ids: Array<string>) {
    return this.deviceRepositoryHttp.getDevicesByIdList(ids);
  }

  getDetailDeviceId(): string {
    if (this.device) {
      return this.device.id;
    }
  }

  // Get the info about the device if exists
  getDetailDevice(): Device {
    if (this.device) {
      return this.device;
    }
  }

  clearDeviceFromService(): void {
    this.device = null;
  }

  setAlarms(alarms: PagedData<ActiveAlarmWithConfigurations>) {
    this.alarms = alarms;
  }

  getAlarms() {
    return this.alarms;
  }

  /**
   * Add device
   * @param device to add
   * @param users to add to the device
   * @param groups to add to the device
   */
  addNewDevice(device: NewDevice, users: string[], groups: string[]): Observable<HttpResponse<Device>> {
    if (device) {
      if (this.userService.isLoggedInUserUM2User()) {
        return this.addNewDeviceWithUM2User(device);
      } else {
        return this.addNewDeviceWithUM1User(device, users, groups);
      }
    }
  }

  /**
   * Add device with UM1 user
   * @param device to add
   * @param users to add to the device
   * @param groups to add to the device
   */
  addNewDeviceWithUM1User(device: NewDevice, users: string[], groups: string[]): Observable<HttpResponse<Device>> {
    return this.deviceRepositoryHttp.setDevice(device).pipe(
      mergeMap((value: HttpResponse<Device>) => {
        return this.setUsers(value.body.id, users);
      }),
      mergeMap((value: HttpResponse<Device>) => {
        return this.setGroups(value.body.id, groups);
      }),
    );
  }

  /**
   * Add device with UM2 user
   * @param device to add
   */
  addNewDeviceWithUM2User(device: NewDevice): Observable<HttpResponse<Device>> {
    return this.deviceRepositoryHttp.setDevice(device);
  }

  /**
   * Get a device by id
   * @param id of device
   */
  getDevice(id: string): Observable<HttpResponse<Device>> {
    return this.deviceRepositoryHttp.getDevice(id);
  }

  /**
   * Update the device data after eidtion
   * @param device data to update
   */
  updateDeviceData(device: Device) {
    return this.deviceRepositoryHttp.updateDevice(device, device.id);
  }

  getGroups(deviceId: string) {
    return this.deviceRepositoryHttp.getGroups(deviceId);
  }

  /**
   * Remove device
   * @param deviceId to delete
   */
  deleteDevice(deviceId: string) {
    return this.deviceRepositoryHttp.deleteDevice(deviceId);
  }

  searchDevices(searchString: string): Observable<HttpResponse<any>> {
    return this.deviceRepositoryHttp.searchDevices(searchString);
  }

  /**
   * Assign a child device to another device
   * @param parentId of device
   * @param childId of device to assign
   */
  assignChildDevice(parentId: string, childId: string): Observable<HttpResponse<any>> {
    return this.deviceRepositoryHttp.assignChildDevice(parentId, childId);
  }

  /**
   * Remove parent device from another device
   * @param childId id of device to unassign the parent from
   */
  unassignDeviceParent(parentId: string, childId: string): Observable<any> {
    return this.deviceRepositoryHttp.unassignDeviceParent(parentId, childId);
  }

  createReportByTemplateId(deviceId: string, templateId: number, backwardsFromNow: number): Observable<HttpResponse<any>> {
    return this.deviceRepositoryHttp.createReportByTemplateId(deviceId, templateId, backwardsFromNow);
  }

  createReport(deviceId: string, body): Observable<HttpResponse<any>> {
    return this.deviceRepositoryHttp.createReport(deviceId, body);
  }

  exportDeviceData(deviceId: string): Observable<any> {
    return this.deviceRepositoryHttp.exportDeviceDataAsCsv(deviceId);
  }

  updateMetadata(metadata: { [key: string]: string }, deviceId: string): Observable<HttpResponse<any>> {
    return this.deviceRepositoryHttp.updateMetadata(metadata, deviceId);
  }

  updateFeatureTree(featureTree: FeatureTree): Observable<HttpResponse<FeatureTree>> {
    return this.deviceRepositoryHttp.updateFeatureTree(featureTree);
  }

  getDeviceRateLimits(deviceId: string): Observable<any> {
    return this.deviceRepositoryHttp.getDeviceRateLimits(deviceId).pipe(map((result) => result.body));
  }

  getAllManufacturers(): Observable<HttpResponse<any>> {
    return this.deviceRepositoryHttp.getAllManufacturers();
  }

  getAllProductionSite(): Observable<HttpResponse<any>> {
    return this.deviceRepositoryHttp.getAllProductionSite();
  }

  getAllBrands(): Observable<HttpResponse<any>> {
    return this.deviceRepositoryHttp.getAllBrand();
  }

  getFeatures(): Observable<HttpResponse<any>> {
    return this.deviceRepositoryHttp.getFeatures();
  }

  getFeatureTree(deviceId: string): Observable<HttpResponse<FeatureTree>> {
    return this.deviceRepositoryHttp.getFeatureTree(deviceId);
  }

  getMqttUsers(deviceId: string): Observable<HttpResponse<any>> {
    return this.deviceRepositoryHttp.getMqttUsers(deviceId);
  }

  getUserDevices(userId: string, page = 0, pageSize = 10): Observable<HttpResponse<any>> {
    return this.deviceRepositoryHttp.getUserDevices(userId, page, pageSize);
  }

  getDefaultImage(tenantSettings: TenantSettings, device?: Device): string {
    const origin = this.imageOrigin ?? '';
    // Seepex default images
    if (tenantSettings?.tenantId === 'a259c0d9-2770-4c7b-a420-c14e249d021b') {
      if (device?.type) {
        if (this.regexBNA.test(device.type)) {
          return origin + 'assets/img/default_pictures_device/BNA_Pump.jpg';
        } else if (this.regex6L.test(device.type)) {
          return origin + 'assets/img/default_pictures_device/SCT_1-stage.jpg';
        } else if (this.regex12S.test(device.type)) {
          return origin + 'assets/img/default_pictures_device/SCT_2-stage.jpg';
        } else if (this.regexSDP.test(device.type)) {
          return origin + 'assets/img/default_pictures_device/SDP_pump.jpg';
        } else if (this.regexBT.test(device.type)) {
          return origin + 'assets/img/default_pictures_device/CS_Pump.jpg';
        } else if (this.regexBN.test(device.type)) {
          return origin + 'assets/img/default_pictures_device/N_pump.jpg';
        } else if (this.regexBT2.test(device.type)) {
          return origin + 'assets/img/default_pictures_device/T_Pump.jpg';
        } else if (this.regexMD.test(device.type)) {
          return origin + 'assets/img/default_pictures_device/D_Pump_without_circle.jpg';
        } else if (this.regexBE.test(device.type)) {
          return origin + 'assets/img/default_pictures_device/E_pump.jpg';
        } else if (this.regexBW.test(device.type)) {
          return origin + 'assets/img/default_pictures_device/W_pump.jpg';
        } else if (this.regexIU.test(device.type)) {
          return origin + 'assets/img/default_pictures_device/M_pump.jpg';
        }
      }
      if (tenantSettings?.defaultDeviceImageFileId) {
        return this.fileRepositoryUi.getTenantDefaultDeviceImage(tenantSettings.defaultDeviceImageFileId);
      }
      return origin + 'assets/img/no-image-available.svg';
    } else if (tenantSettings?.defaultDeviceImageFileId) {
      // returned saved default device image
      return this.fileRepositoryUi.getTenantDefaultDeviceImage(tenantSettings.defaultDeviceImageFileId);
    }

    // general default device image, if no image is set
    return origin + 'assets/img/no-image-available.svg';
  }

  getDefaultEmailImage(tenantSettings: TenantSettings) {
    const origin = this.imageOrigin ?? '';
    return origin + 'assets/icons/icon-enlarge-email-logo.svg';
  }

  setPreviewDefaultDeviceImage(previewDefaultDeviceImage: File): void {
    this.previewDefaultDeviceImage = previewDefaultDeviceImage;
  }

  getPreviewDefaultDeviceImage(): File {
    return this.previewDefaultDeviceImage;
  }

  setPreviewDefaultEmailImage(previewDefaultEmailImage: File): void {
    this.previewDefaultEmailImage = previewDefaultEmailImage;
  }

  getPreviewDefaultEmailImage(): File {
    return this.previewDefaultEmailImage;
  }

  setUsers(deviceId: string, userIds: string[]): Observable<HttpResponse<Device>> {
    return this.deviceRepositoryHttp.setUsers(deviceId, userIds);
  }

  assignUser(deviceId: string, userId: string): Observable<any> {
    return this.deviceRepositoryHttp.assignUser(deviceId, userId);
  }

  removeUser(deviceId: string, userId: string): Observable<any> {
    return this.deviceRepositoryHttp.removeUser(deviceId, userId);
  }

  setGroups(deviceId: string, groupIds: string[]): Observable<HttpResponse<Device>> {
    return this.deviceRepositoryHttp.setGroups(deviceId, groupIds);
  }

  completeProvision(deviceId: string): Observable<any> {
    return this.deviceRepositoryHttp.completeProvision(deviceId);
  }

  inProvision(deviceId: string): Observable<any> {
    return this.deviceRepositoryHttp.inProvision(deviceId);
  }

  getSimHistory(iccid: string): Observable<any> {
    return this.deviceRepositoryHttp.getSimHistory(iccid);
  }

  getSimDetails(iccid: string): Observable<any> {
    return this.deviceRepositoryHttp.getSimDetails(iccid);
  }

  getDeviceMetadata(deviceId: string): Observable<DeviceMetadataModel> {
    return this.deviceRepositoryHttp.getDeviceMetadata(deviceId);
  }
}
