import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { FeatureNames, ServicePointFeatures, TenantSettings } from '@deprecated/api-interfaces';
import { ThemingService } from '@ird/ng-base';
import { FileRepositoryUi } from '../repositories/file-ui.repository';
@Injectable({
  providedIn: 'root',
})
export class TenantConfigService {
  config: TenantSettings;
  service: { features: any[] };

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private themingService: ThemingService,
    private fileRepositoryUI: FileRepositoryUi,
    private platform: Platform,
  ) {}

  setConfig(config) {
    this.config = config;
    this.themingService.loadTheme(config.themeDefinition);
    this.setNavigationTenantImage(this.fileRepositoryUI.getTenantImage(config.logoFileId));
  }

  getConfig() {
    return this.config;
  }

  getThemeDefinition() {
    return this.config.themeDefinition;
  }

  getTenantId() {
    return this.config.tenantId;
  }

  getLogoFileId() {
    return this.config.logoFileId;
  }

  getAppUrl(): string {
    return this.getServicePointFeatures()[ServicePointFeatures.APP_URL];
  }

  getImprintUrl(): string {
    return this.getServicePointFeatures()[ServicePointFeatures.IMPRINT_URL];
  }

  getBrandName(): string {
    return this.getServicePointFeatures()[ServicePointFeatures.BRAND_NAME];
  }

  getAppName(): string {
    return this.getServicePointFeatures()[ServicePointFeatures.APP_NAME];
  }

  public getServicePointFeatures(): { [key: string]: any } {
    return this.config?.features.find((feature) => feature[ServicePointFeatures.NAME] === FeatureNames.SERVICE_POINT);
  }

  public setNavigationTenantImage(url: string): void {
    if (url) {
      if (this.platform.IOS) {
        this._document.body.style.setProperty('--logo-url', 'url(' + url + ')');
      } else {
        this._document.body.style.setProperty('--logo-url', 'url("' + url + '")');
      }
    } else {
      this._document.body.style.removeProperty('--logo-url');
    }
  }

  public isFeatureEnabled(feature: string): boolean {
    return this.getServicePointFeatures()[feature];
  }
}
