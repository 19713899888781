import { AppService } from '../shared/services/app.service';
import { DeviceService } from '../shared/services/device.service';

const uuidRegex = /([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})/;

export function appInitializerFactory(appService: AppService, deviceService: DeviceService) {
  return () => {
    return new Promise((resolve) => {
      appService.initApp().finally(() => {
        const url = new URL(window.location.href);
        const verificationUrl = url.toString().includes('verification-success');
        if (verificationUrl) {
          const assetId: string = url.searchParams.get('assetId');
          if (assetId && uuidRegex.test(assetId)) {
            deviceService.initDevice(assetId).finally(() => {
              resolve(null);
            });
          } else {
            resolve(null);
          }
        } else {
          const uuidMatches = url.href.match(uuidRegex);
          if (uuidMatches && uuidMatches.length > 0) {
            const uuid = uuidMatches[0];
            deviceService.initDevice(uuid).finally(() => {
              resolve(null);
            });
          } else {
            resolve(null);
          }
        }
      });
    });
  };
}
