import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SepoDevice } from '@deprecated/api-interfaces';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { AuthService } from '../../auth/service/auth.service';
import { DeviceRepository } from '../repositories/device.repository';
import { KpiLogsService } from './kpi-logs.service';
import { WarrantyService } from './warranty.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  // Device behavior subject
  private device$ = new BehaviorSubject<SepoDevice>(null);

  private deviceId: string;

  /**
   * Device changed observable
   */
  public deviceChanged = this.device$.asObservable();

  constructor(
    private deviceRepository: DeviceRepository,
    private authService: AuthService,
    private kpiLogsService: KpiLogsService,
    private warrantyService: WarrantyService,
    private router: Router,
  ) {}

  /**
   * Sets the device ID and gets the device from the server
   *
   * @param deviceId device ID
   */
  setDeviceId(deviceId: string): void {
    this.deviceId = deviceId;
    localStorage.removeItem('deviceId');
    localStorage.setItem('deviceId', deviceId);
    this.kpiLogsService.sendInitEvent(deviceId);
  }

  /**
   * Returns the device id, when it was set
   *
   * @returns ID of device
   */
  public getDeviceId(): string {
    return this.deviceId ?? localStorage.getItem('deviceId');
  }

  getDevice(deviceId: string): Promise<SepoDevice> {
    return new Promise((resolve, reject) => {
      this.deviceRepository.getDevice(deviceId).subscribe({
        next: (device) => {
          if (device) {
            this.device$.next(device);
            this.warrantyService.checkWarrantyStatus(deviceId);
            resolve(device);
          } else {
            reject();
          }
        },
        error: (err) => {
          this.device$.next(null);
          reject();
        },
      });
    });
  }

  addToPrivateTeam(deviceId: string): Promise<void> {
    if (this.authService.isTokenValid()) {
      return firstValueFrom(this.deviceRepository.addToPrivateTeam(deviceId));
    } else {
      return Promise.resolve();
    }
  }

  initDevice(deviceId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const uuidRegex = /^([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})$/;
      if (uuidRegex.test(deviceId)) {
        this.setDeviceId(deviceId);
        this.addToPrivateTeam(deviceId).finally(() => {
          this.getDevice(deviceId)
            .then((value) => {
              if (value.redirectUrl) {
                window.open(value.redirectUrl, '_self');
              } else {
                resolve();
              }
            })
            .catch(() => {
              reject();
              this.router.navigate(['/qrassignment']);
            });
        });
      } else {
        reject();
      }
    });
  }
}
