// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host-context(.no-padding) .content {
  padding: 0;
}

.content {
  padding: 0 16px;
}

.header {
  display: flex;
  width: 100%;
}

.titlebar {
  align-items: baseline;
}

.backbutton {
  margin: 1rem 0;
  width: 24px;
  cursor: pointer;
}
.backbutton spx-icon {
  width: 24px;
  height: 24px;
}

.page-title {
  padding: 16px;
}

.back-icon {
  padding-left: 16px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxheW91dC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFLFVBQUE7QUFBSjs7QUFJQTtFQUNFLGVBQUE7QUFERjs7QUFJQTtFQUNFLGFBQUE7RUFDQSxXQUFBO0FBREY7O0FBSUE7RUFDRSxxQkFBQTtBQURGOztBQUlBO0VBQ0UsY0FBQTtFQUNBLFdBQUE7RUFDQSxlQUFBO0FBREY7QUFHRTtFQUNFLFdBQUE7RUFDQSxZQUFBO0FBREo7O0FBS0E7RUFDRSxhQUFBO0FBRkY7O0FBS0E7RUFDRSxrQkFBQTtBQUZGIiwiZmlsZSI6ImxheW91dC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0LWNvbnRleHQoLm5vLXBhZGRpbmcpIHtcbiAgLmNvbnRlbnQge1xuICAgIHBhZGRpbmc6IDA7XG4gIH1cbn1cblxuLmNvbnRlbnQge1xuICBwYWRkaW5nOiAwIDE2cHg7XG59XG5cbi5oZWFkZXIge1xuICBkaXNwbGF5OiBmbGV4O1xuICB3aWR0aDogMTAwJTtcbn1cblxuLnRpdGxlYmFyIHtcbiAgYWxpZ24taXRlbXM6IGJhc2VsaW5lO1xufVxuXG4uYmFja2J1dHRvbiB7XG4gIG1hcmdpbjogMXJlbSAwO1xuICB3aWR0aDogMjRweDtcbiAgY3Vyc29yOiBwb2ludGVyO1xuXG4gIHNweC1pY29uIHtcbiAgICB3aWR0aDogMjRweDtcbiAgICBoZWlnaHQ6IDI0cHg7XG4gIH1cbn1cblxuLnBhZ2UtdGl0bGUge1xuICBwYWRkaW5nOiAxNnB4O1xufVxuXG4uYmFjay1pY29uIHtcbiAgcGFkZGluZy1sZWZ0OiAxNnB4O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./apps/scs-service-point/src/app/layout/components/layout/layout.component.scss"],"names":[],"mappings":"AACE;EACE,UAAA;AAAJ;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,aAAA;EACA,WAAA;AADF;;AAIA;EACE,qBAAA;AADF;;AAIA;EACE,cAAA;EACA,WAAA;EACA,eAAA;AADF;AAGE;EACE,WAAA;EACA,YAAA;AADJ;;AAKA;EACE,aAAA;AAFF;;AAKA;EACE,kBAAA;AAFF;AACA,whCAAwhC","sourcesContent":[":host-context(.no-padding) {\n  .content {\n    padding: 0;\n  }\n}\n\n.content {\n  padding: 0 16px;\n}\n\n.header {\n  display: flex;\n  width: 100%;\n}\n\n.titlebar {\n  align-items: baseline;\n}\n\n.backbutton {\n  margin: 1rem 0;\n  width: 24px;\n  cursor: pointer;\n\n  spx-icon {\n    width: 24px;\n    height: 24px;\n  }\n}\n\n.page-title {\n  padding: 16px;\n}\n\n.back-icon {\n  padding-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
