import * as cloneDeep from 'lodash.clonedeep';

export class CsvUtils {
  /**
   * Download csv file in browser.
   *
   * @param csvContent Formatted csv-string with columns and data.
   * @param fileName Name of file.
   */
  public static downloadCsv(csvContent: string, fileName: string): void {
    const blob = new Blob(['\ufeff' + csvContent], { type: 'text/csv; charset=utf-8' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  /**
   * Convert a list of objects to a csv string.
   *
   * @param data {[key: string]: string}[] - A list of objects
   * @returns string
   *
   */
  public static toCsv(data: any): string {
    if (!data.length) {
      return '';
    } else {
      return [Object.keys(data[0]), ...data.map((item: any) => Object.values(item))].map((e) => e.join(',')).join('\n');
    }
  }

  // this method will handle dates too with comma separated parameters format also
  public static generateCsv(data: any[]): string {
    const replacer = (key: string, value: any) => {
      if (value === null) return '';
      if (typeof value === 'string' && value.includes(',')) {
        return value.replace(/"/g, '');
      }
      return value;
    };

    const header = Object.keys(data[0]);
    const csvRows = data.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));

    csvRows.unshift(header.join(','));

    return csvRows.join('\n');
  }

  public static seriesToCsv(series: any[]): string {
    let csvString = '';
    series.forEach((serie) => {
      csvString = csvString + `${serie.name}\n`;
      csvString = csvString + `${serie.data.map((item) => item[0])}\n`;
      csvString = csvString + `${serie.data.map((item) => item[1])}\n`;
    });
    return csvString;
  }

  public static monitoringToCsv(aggregationType: 'AVG' | 'MIN' | 'MAX' | 'BAND', series: any[], legend?: any): string {
    let csvString = '';
    const header = ['Timestamp', 'Sortable time stamp (Unix Timestamp ms)'];
    const allDataPointsTimestamp = new Set();
    const sink = [];
    let positionVal = 0;
    let deepClonedSeries = cloneDeep(series);
    deepClonedSeries = this.modifyData(deepClonedSeries, legend);

    deepClonedSeries.forEach((serie: any, _index: number) => {
      if (serie?.name) {
        let headerName: string;
        if (serie?.id.endsWith('-area-min') || aggregationType === 'MIN') {
          headerName = serie?.name + ' MIN';
        } else if (serie?.id.endsWith('-area-max') || aggregationType === 'MAX') {
          headerName = serie?.name + ' MAX';
        } else {
          headerName = serie?.name;
        }
        header.push(headerName);
        positionVal = positionVal + 1;
        serie?.data &&
          serie?.data?.map((dataItem: number[]) => {
            if (!Number.isNaN(Number(dataItem[1]))) {
              if (!(serie?.id.endsWith('-area-min') && serie?.id.endsWith('-area-max'))) {
                allDataPointsTimestamp.add(dataItem[0]);
              }
              sink.push({
                timestamp: dataItem[0],
                value: dataItem[1],
                position: positionVal,
              });
            }
          });
      }
    });

    const sortedAllDataPointsTimestamp = Array.from(allDataPointsTimestamp).sort();
    sortedAllDataPointsTimestamp.forEach((dataPoint) => {
      const dataPoints = sink.filter((sinkItem) => sinkItem?.timestamp === dataPoint);
      const row = [];
      const originalDate = new Date(dataPoints[0].timestamp);
      const formattedDate = originalDate.toDateString().slice(4) + ' ' + originalDate.toTimeString();
      row.push(formattedDate);
      row.push(dataPoints[0].timestamp);
      dataPoints.forEach((dataPoint) => {
        row[dataPoint?.position + 1] = dataPoint?.value;
      });
      csvString = csvString + `${row}\n`;
    });
    return `${header}\n` + csvString;
  }

  public static modifyData(originalArray, legend) {
    if (!Array.isArray(originalArray) || originalArray.length === 0) {
      throw new Error('Input must be a non-empty array of objects');
    }

    const idObjects = [];
    const areaMinObjects = [];
    const areaMaxObjects = [];

    const baseIdToIndexMap = new Map<string, number>();

    // Categorize objects and populate baseIdToIndexMap
    originalArray.forEach((obj, index) => {
      const id = obj.id;
      if (!id.endsWith('-area-min') && !id.endsWith('-area-max')) {
        idObjects.push({ ...obj, name: legend?.data[index]?.name });
        baseIdToIndexMap.set(id, index);
      } else if (id.endsWith('-area-min')) {
        areaMinObjects.push(obj);
      } else if (id.endsWith('-area-max')) {
        areaMaxObjects.push(obj);
      }
    });

    // Sort areaMinObjects and areaMaxObjects by base ID
    areaMinObjects.sort((a, b) => {
      const baseIdA = a.id.replace('-area-min', '');
      const baseIdB = b.id.replace('-area-min', '');
      return baseIdA.localeCompare(baseIdB);
    });

    areaMaxObjects.sort((a, b) => {
      const baseIdA = a.id.replace('-area-max', '');
      const baseIdB = b.id.replace('-area-max', '');
      return baseIdA.localeCompare(baseIdB);
    });

    const rearrangedArray = [];

    // Merge objects into rearrangedArray in the desired order
    idObjects.forEach((baseObj) => {
      rearrangedArray.push(baseObj);

      const baseId = baseObj.id;

      // Find matching area-min and area-max objects
      const matchingAreaMin = areaMinObjects.find((obj) => obj.id === `${baseId}-area-min`);
      const matchingAreaMax = areaMaxObjects.find((obj) => obj.id === `${baseId}-area-max`);

      if (matchingAreaMin) {
        rearrangedArray.push(matchingAreaMin);
      }
      if (matchingAreaMax) {
        rearrangedArray.push(matchingAreaMax);
      }
    });

    // Update data in area-max objects based on area-min and area-max data
    rearrangedArray.forEach((obj) => {
      const id = obj.id;
      if (id.endsWith('-area-max')) {
        const baseId = id.replace('-area-max', '');

        // Find corresponding area-min and area-max objects
        const areaMinObj = areaMinObjects.find((obj) => obj.id === `${baseId}-area-min`);
        const areaMaxObj = areaMaxObjects.find((obj) => obj.id === `${baseId}-area-max`);

        if (areaMinObj && areaMaxObj) {
          const areaMinData = areaMinObj.data || [];
          const areaMaxData = areaMaxObj.data || [];
          const mergedData: [number, number][] = [];

          // Calculate area-max data by summing area-min and area-max values
          areaMaxData.forEach(([timestamp, maxValue]) => {
            const matchingMinValue = areaMinData.find(([minTimestamp]) => minTimestamp === timestamp);
            const minValue = matchingMinValue ? matchingMinValue[1] : 0;
            mergedData.push([timestamp, minValue + maxValue]);
          });

          obj.data = mergedData;
        }
      }
    });
    return rearrangedArray;
  }
}
