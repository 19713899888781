import { AfterContentChecked, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NotesSecureAction, SepoDevice, ServicePointFeatures } from '@deprecated/api-interfaces';
import { Observable } from 'rxjs';
import { AuthService } from '../../../auth/service/auth.service';
import { DeviceService } from '../../../shared/services/device.service';
import { TenantConfigService } from '../../../shared/services/tenant-config.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements AfterContentChecked {
  // template variables
  public device$: Observable<SepoDevice>;
  public servicePointFeatures = ServicePointFeatures;
  NotesSecureAction = NotesSecureAction;

  constructor(
    private deviceService: DeviceService,
    private auth: AuthService,
    private router: Router,
    private tenantConfigService: TenantConfigService,
    private cdr: ChangeDetectorRef,
  ) {
    this.device$ = this.deviceService.deviceChanged;
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  isLoggedIn(): boolean {
    return this.auth.isTokenValid() && this.router.url !== '/login';
  }

  public logout() {
    this.auth.logout();
  }

  isFeatureEnabled(feature: string): boolean {
    return !feature || this.tenantConfigService.isFeatureEnabled(feature);
  }

  navigateWithDevice(path: string): void {
    const deviceId = this.deviceService.getDeviceId();
    if (deviceId) {
      this.router.navigate([`${path}/${deviceId}`]);
    } else {
      this.router.navigate([path]);
    }
  }
}
