export class Pageable {
  pageSize: number;
  pageNumber: number;
  offset!: number;
  paged!: boolean;
  unpaged!: boolean;
  sort?: Sort | null;

  constructor(pageSize: number = 20, pageNumber: number = 0, sort: Sort | null = null) {
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
    this.sort = sort;
  }

  /**
   * Serializes this {@link Pageable} into a generic object.
   * The resulting object will basically be a clone of this {@link Pageable},
   * except fields that are undefined or null.
   *
   * @returns
   */
  public serialize(additionalParams?: Record<string, string | number>): Record<string, string | number> {
    const params: { [key: string]: string | number } = {};

    Object.entries({ ...this, ...(additionalParams ?? {}) }).forEach(([key, value]) => {
      if (value !== undefined && value !== null && typeof value !== 'object') {
        params[key] = value;
      } else if (value !== undefined && value !== null) {
        params[key] = JSON.stringify(value);
      }
    });

    return params;
  }
}

export class Sort {
  sortOrders: Array<{ property: string; direction: string }>;

  constructor(sortOrders: Array<{ property: string; direction: string }>) {
    this.sortOrders = sortOrders;
  }
}

export type NgSortDirection = 'asc' | 'desc' | '';
export interface NgSort {
  active: string;
  direction: NgSortDirection;
}

export class FilteredPageable extends Pageable {
  term?: string;

  constructor(pageSize: number, pageNumber: number, sort: Sort, term: string) {
    super(pageSize, pageNumber, sort);
    this.term = term;
  }
}
