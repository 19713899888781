import { DataConversionCacheService } from './data-conversion-cache.service';
import { evaluate } from 'mathjs';
import { DataConversionCalculationDto } from '../models/data-conversion-calculation-dto.model';
import { ValueType } from '../models/value-type.model';

export class DataConversionBaseService {
  private dataConversionCacheService = new DataConversionCacheService();

  async convert<T>(value: string | number, label: boolean, calculationDto: DataConversionCalculationDto): Promise<T> {
    // Return raw value if no calculationDto was found:
    if ((!calculationDto?.toReferenceUnit && !calculationDto?.fromReferenceUnit) || !value) {
      return value as T;
    }

    // Convert TO referenceUnit
    if (!calculationDto?.fromReferenceUnit && calculationDto?.toReferenceUnit) {
      return label
        ? (calculationDto.toReferenceUnit.targetUnit as T)
        : (this.calculate(+value, calculationDto.toReferenceUnit.formula) as T);
    }

    // Convert FROM referenceUnit
    if (!calculationDto?.toReferenceUnit && calculationDto?.fromReferenceUnit) {
      return label
        ? (calculationDto.fromReferenceUnit.targetUnit as T)
        : (this.calculate(+value, calculationDto.fromReferenceUnit.formula) as T);
    }

    // Convert TO referenceUnit and then FROM referenceUnit
    if (calculationDto?.toReferenceUnit && calculationDto?.fromReferenceUnit) {
      return label
        ? (calculationDto.fromReferenceUnit.targetUnit as T)
        : (this.calculate(this.calculate(+value, calculationDto.toReferenceUnit.formula), calculationDto.fromReferenceUnit.formula) as T);
    }
    return value as T;
  }

 getCacheItem(key: string): DataConversionCalculationDto | void {
    return this.dataConversionCacheService.get(key);
  }

  setCacheItem(dataConversionCalculationDto: DataConversionCalculationDto) {
    this.dataConversionCacheService.set(
      this.formatCacheKey(
        dataConversionCalculationDto?.toReferenceUnit?.valueType ?? dataConversionCalculationDto?.fromReferenceUnit?.valueType,

        dataConversionCalculationDto?.toReferenceUnit?.sourceUnit ?? dataConversionCalculationDto?.fromReferenceUnit?.sourceUnit,

        dataConversionCalculationDto?.fromReferenceUnit
          ? dataConversionCalculationDto.fromReferenceUnit?.targetUnit
          : dataConversionCalculationDto.toReferenceUnit?.targetUnit,
      ),
      dataConversionCalculationDto,
    );
  }

  protected formatCacheKey(valueType: ValueType, sourceUnit: string, targetUnit: string): string {
    return `${valueType}-${sourceUnit}-${targetUnit}`;
  }

  private calculate(value: number, formula: string): number {
    if (value && formula) {
      return evaluate(formula.replace('x', value.toString()));
    }
    return value;
  }
}
