import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExtendedWarrantyResponse, WarrantyStatusResponse } from '@deprecated/api-interfaces';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ExtendedWarrantyPayload } from '../models/extended-warranty-payload';

@Injectable({
  providedIn: 'root',
})
export class WarrantyRepository {
  constructor(private http: HttpClient) {}

  getWarrantyStatus(deviceId: string): Observable<WarrantyStatusResponse> {
    const url = environment.warranty.getWarrantyStatus.replace('{deviceId}', deviceId);
    return this.http.get<WarrantyStatusResponse>(url);
  }

  checkAndActivateExtendedWarranty(extendedWarrantyPayload: ExtendedWarrantyPayload) : Observable<ExtendedWarrantyResponse> {
    return this.http.post<ExtendedWarrantyResponse>(environment.warranty.checkAndActivateExtendedWarranty, extendedWarrantyPayload);
  }
}
