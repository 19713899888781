import { ThresholdAlarmConfiguration } from '../alarm';
import { ConfiguredThresholdDTO, DefaultThresholdColor, KpiThreshold, ThresholdDTO, ThresholdLevel } from '../shared';

type ThresholdMapperType = ConfiguredThresholdDTO | ThresholdDTO;

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class ThresholdMapperUtils {
  public static mapAlertThresholdsToThresholdDTO(
    alerts: Array<ThresholdAlarmConfiguration>,
    includeValuesToTrigger = false,
  ): Array<ThresholdMapperType> {
    const thresholds: Array<ThresholdMapperType> = [];

    if (!alerts || alerts?.length === 0) {
      return null;
    }

    [ThresholdLevel.INFO, ThresholdLevel.WARN, ThresholdLevel.ERROR].forEach((level) => {
      const basicThreshold = new ConfiguredThresholdDTO();
      basicThreshold.type = level;

      const configuredAlert = alerts?.find((alert) => alert?.type === level);

      if (!configuredAlert) {
        return;
      }

      const { thresholdMax, thresholdMin, type, enabled, numValuesToTrigger } = configuredAlert;

      if (includeValuesToTrigger) {
        basicThreshold.numValuesToTrigger = numValuesToTrigger;
      }

      if (typeof thresholdMin === 'number') {
        thresholds.push({ ...basicThreshold, min: thresholdMin, type, enabled });
      } else {
        thresholds.push({ ...basicThreshold });
      }

      if (typeof thresholdMax === 'number') {
        thresholds.push({ ...basicThreshold, max: thresholdMax, type, enabled });
      } else {
        thresholds.push({ ...basicThreshold });
      }
    });

    return thresholds.sort((a, b) => (a.min ?? a.max) - (b.min ?? b.max));
  }

  public static mapKpiThresholdsToThresholdDTO(kpiThresholds: Array<KpiThreshold>): Array<ThresholdDTO> {
    const thresholdColorEntries = Object.entries(DefaultThresholdColor);

    const direction = kpiThresholds?.[0]?.color === DefaultThresholdColor.SUCCESS ? 'max' : 'min';

    return kpiThresholds?.map((threshold) => {
      const { color, startValue } = threshold ?? {};
      const mappedThreshold: ThresholdDTO = {
        enabled: true,
        type: thresholdColorEntries.find((entry) => entry[1] === color)?.[0] as ThresholdLevel,
      };
      mappedThreshold[direction] = startValue;
      return mappedThreshold;
    });
  }

  public static mapThresholdDTOsToKpiThresholds(thresholdDTOs: Array<ThresholdDTO>): Array<KpiThreshold> {
    return thresholdDTOs.map((threshold) => ({
      startValue: threshold.min ?? threshold.max,
      color: DefaultThresholdColor[threshold.type],
    }));
  }
}
