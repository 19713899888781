import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExtendedWarrantyResponse, WarrantyStatusResponse } from '@deprecated/api-interfaces';
import { PathReplaceUtils } from '@ird/shared-utils';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ExtendedWarrantyPayload } from '../models/extended-warranty-payload';

@Injectable({
  providedIn: 'root',
})
export class WarrantyRepository {
  constructor(private http: HttpClient) {}

  getWarrantyStatus(deviceId: string, tenantId: string): Observable<WarrantyStatusResponse> {
    const path = environment.warranty.getWarrantyStatus;
    const url = PathReplaceUtils.replace(path, {
      deviceId,
      tenantId,
    });
    return this.http.get<WarrantyStatusResponse>(url);
  }

  checkAndActivateExtendedWarranty(
    extendedWarrantyPayload: ExtendedWarrantyPayload,
    tenantId: string,
    frontendSessionId: string,
    placementCode: string,
    languageId: string,
  ): Observable<ExtendedWarrantyResponse> {
    const path = environment.warranty.checkAndActivateExtendedWarranty;
    const options = {
      'Content-Type': 'application/json',
    };
    return this.http.post<ExtendedWarrantyResponse>(
      path,
      { extendedWarrantyPayload, tenantId, frontendSessionId, placementCode, languageId },
      { observe: 'body', headers: options },
    );
  }
}
