import { Injectable } from '@angular/core';
import { ExtendedWarrantyResponse, WarrantyStatusResponse } from '@deprecated/api-interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { ExtendedWarrantyPayload } from '../models/extended-warranty-payload';
import { WarrantyRepository } from '../repositories/warranty.repository';

@Injectable({
  providedIn: 'root',
})
export class WarrantyService {
  private _warrantyStatus$ = new BehaviorSubject<WarrantyStatusResponse>(null);

  /**
   * Device changed observable
   */
  public warrantyStatusChanged = this._warrantyStatus$.asObservable();

  constructor(private warrantyRepository: WarrantyRepository) {}

  getWarrantyStatus(deviceId: string, tenantId: string): Observable<WarrantyStatusResponse> {
    return this.warrantyRepository.getWarrantyStatus(deviceId, tenantId);
  }

  checkWarrantyStatus(deviceId: string, tenantId: string): Promise<WarrantyStatusResponse> {
    return new Promise((resolve, reject) => {
      this.warrantyRepository.getWarrantyStatus(deviceId, tenantId).subscribe({
        next: (value) => {
          this._warrantyStatus$.next(value);
          resolve(value);
        },
        error: (err) => {
          this._warrantyStatus$.next(null);
          reject(err);
        },
      });
    });
  }

  checkAndActivateExtendedWarranty(
    extendedWarrantyPayload: ExtendedWarrantyPayload,
    tenantId: string,
    frontendSessionId: string,
    placementCode: string,
    languageId: string,
  ): Observable<ExtendedWarrantyResponse> {
    return this.warrantyRepository.checkAndActivateExtendedWarranty(
      extendedWarrantyPayload,
      tenantId,
      frontendSessionId,
      placementCode,
      languageId,
    );
  }
}
