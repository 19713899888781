/* eslint-disable max-len */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import endpoints from './endpoints';

export const environment = {
  ...endpoints(),
  production: false,
  imprintUrl: '',
  devOrigin: null,
  xTokenCookieName: 'x_token_test',
  recaptchaSiteKey: '6Le3y2EpAAAAAFD7hboOLd4iyAHKo_5K84jjKA9m',
};
