// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-title {
  margin-left: 1rem;
}

.logo {
  content: var(--logo-url);
  height: 25px;
}

.logo::before {
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: block;
  height: 30px;
  width: 117px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImhlYWRlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGlCQUFBO0FBQ0Y7O0FBRUE7RUFDRSx3QkFBQTtFQUNBLFlBQUE7QUFDRjs7QUFFQTtFQUNFLDRCQUFBO0VBQ0Esd0JBQUE7RUFDQSxXQUFBO0VBQ0EsY0FBQTtFQUNBLFlBQUE7RUFDQSxZQUFBO0FBQ0YiLCJmaWxlIjoiaGVhZGVyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnBhZ2UtdGl0bGUge1xuICBtYXJnaW4tbGVmdDogMXJlbTtcbn1cblxuLmxvZ28ge1xuICBjb250ZW50OiB2YXIoLS1sb2dvLXVybCk7XG4gIGhlaWdodDogMjVweDtcbn1cblxuLmxvZ286OmJlZm9yZSB7XG4gIGJhY2tncm91bmQtcmVwZWF0OiBuby1yZXBlYXQ7XG4gIGJhY2tncm91bmQtc2l6ZTogY29udGFpbjtcbiAgY29udGVudDogJyc7XG4gIGRpc3BsYXk6IGJsb2NrO1xuICBoZWlnaHQ6IDMwcHg7XG4gIHdpZHRoOiAxMTdweDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./apps/scs-service-point/src/app/layout/components/header/header.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,wBAAA;EACA,YAAA;AACF;;AAEA;EACE,4BAAA;EACA,wBAAA;EACA,WAAA;EACA,cAAA;EACA,YAAA;EACA,YAAA;AACF;AACA,wtBAAwtB","sourcesContent":[".page-title {\n  margin-left: 1rem;\n}\n\n.logo {\n  content: var(--logo-url);\n  height: 25px;\n}\n\n.logo::before {\n  background-repeat: no-repeat;\n  background-size: contain;\n  content: '';\n  display: block;\n  height: 30px;\n  width: 117px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
