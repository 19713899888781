/**
 * Type containing all properties of TO which are not in FROM
 */
export type Omitter<FROM, TO> = Omit<TO, keyof FROM>;

function getValue<T, K extends keyof T>(data: T, key: K) {
  return data[key];
}

/**
 * Maps data from the from Object to a new object of type to.
 * Copies all the values from from to the property with the same name in to.
 * If the property does not exists in to it will be skipped.
 * If the property does not exists in from the value in toAdd will be used.
 * If the property value exists in from and toAdd the toAdd value is used to override the from value. *
 *
 * @param from the base object to use its values from
 * @param toAdd the properties with values which do not exist in from but are needed in to
 * @param sample a sample object of to, containing all properties with dummy values (value does not matter)
 * @returns a new object containing all mapped values from from or toAdd object
 */
export function genericMap<FROM, TO>(from: FROM, toAdd: Omitter<FROM, TO>, sample: TO): TO {
  const keys = Object.keys(sample);
  const to: Record<string, any> = {};
  keys.forEach((key: string) => {
    const fromValue = getValue(from, key as keyof FROM);
    const toAddValue = getValue(toAdd, key as keyof Omitter<FROM, TO>);

    to[key] = toAddValue ?? fromValue;
  });

  return to as TO;
}
